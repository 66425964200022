<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import getAlluser from "@/functions/httpRequest/ExternalConnection/CorrectionRequestTable.js";
export default {
  name: "app",
  // socket: "",

  mounted() {
    // console.log("test");
    // this.$socket.emit("newUpdate", "HRDINQUIRY"); //Testing of socket.io
    // this.Check_LogIn(this.$io);
    // console.log();
    // console.log(store.state.userinfo.EmployeeCode);
  },
  created() {},
};
</script>

<style>
* {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: "Digital";
  src: local("Digital"), url(./fonts/magetta/MagettasDemoBold.ttf) format("truetype"); /*  ADDITIONAL TTF FILE FOR FONT  */
}
</style>
