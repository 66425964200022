import axios from "axios";

//*************************//
// FOR CONFIRMATION OBJECT //
//*************************//

const alert = {
  position: "top-center",
  timeout: 3284,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

//*************************//
//   INQUIRY TYPE OBJECT   //
//*************************//

const inquiryType = [
  {
    en: "Inquiries About Original Products",
    ja: "オリジナル製品に関する問い合わせ",
    value: "オリジナル製品に関する問い合わせ",
  },
  {
    en: "Inquiries About The Rule Book",
    ja: "ルールブックに関する問い合わせ",
    value: "ルールブックに関する問い合わせ",
  },
  {
    en: "Inquiries about CAD drawings",
    ja: "CAD図面に関する問い合わせ",
    value: "CAD図面に関する問い合わせ",
  },
  {
    en: "Inquiries about electrical drawings",
    ja: "電気図面に関する問い合わせ",
    value: "電気図面に関する問い合わせ",
  },
  {
    en: "Inquiries regarding application drawings",
    ja: "申請図面に関する問い合わせ",
    value: "申請図面に関する問い合わせ",
  },
  {
    en: "Inquiries about construction drawings",
    ja: "施工図面に関する問い合わせ",
    value: "施工図面に関する問い合わせ",
  },
  {
    en: "Inquiries about ordering",
    ja: "発注に関する問い合わせ",
    value: "発注に関する問い合わせ",
  },
  {
    en: "Other",
    ja: "その他",
    value: "その他",
  },
];

//************************************//
//    OBJECT FOR WEB COMPUTATION      //
//************************************//

const normalForCorrection = {
  forControlNumber: 2,
  others: 0,
  class: "ml-1",
  otherClass: "ml-1 mr-3",
  outlined: false,
  elevation: 3,
  color: "#ffffff",
  classCard: "ma-2",
  jobKind: 7,
  jobKindClass: "ma-3",
  sender: 5,
  senderClass: "ma-3",
  senderStyle: "margin-left: 2% !important",
  dateToCancelClass: "",
};

//************************************//
//   OBJECT FOR MOBILE COMPUTATION    //
//************************************//

const mobileForCorrection = {
  forControlNumber: 12,
  others: 12,
  class: "ml-1 mr-4 mb-n6",
  otherClass: "ml-1 mr-n2 ",
  outlined: true,
  elevation: 0,
  color: "#ffffff",
  classCard: "ma-0",
  jobKind: 12,
  jobKindClass: "",
  sender: 12,
  senderClass: "mt-n7",
  senderStyle: "",
  dateToCancelClass: "mt-n10",
};

//*************************************//
//   AUTHENTICATION && URL OBJECT    10.169.140.117 //
//************************************//

const auth = {
  apiKey: {
    headers: { "x-api-key": process.env.VUE_APP_BASEAPIKEY },
  },
  masterApi:{
   headers:{ "master-api" : process.env.VUE_APP_MASTER_KEY},
  },
  BaseUrl:
    process.env.NODE_ENV == "development"
      ? "http://10.169.142.104:3000/api"
      : process.env.VUE_APP_APIGATEWAY,
};

//***********************************//
//       EMPLOYEE PICTIRE            //
//***********************************//

const EmployeePicture = {
  Picture: (EmployeeCode) =>
    `http://hrdapps48:3001/ftp/employee_pictures/0${EmployeeCode}.jpg`,
};

//***********************************//
// ADDITIONAL EMAIL FOR CORRECTION   //
//***********************************//

const additionalEmail = () => {
  return [
    { Email: "interior04@hrd-s.com", Name: "interior04" },
    { Email: "interior02@hrd-s.com", Name: "interior02" },
    { Email: "hac-c@hrd-s.com", Name: "hac-c" },
    { Email: "hac-a@hrd-s.com", Name: "hac-a" },
    { Email: "mailing@hrd-s.com", Name: "mailing" },
    { Email: "interior03@hrd-s.com", Name: "interior03" },
    { Email: "interior09@hrd-s.com", Name: "interior09" },
    { Email: "gaibuhiroi@hrd-s.com", Name: "gaibuhiroi" },
    { Email: "hac-d@hrd-s.com", Name: "hac-d" },
    { Email: "i-cube03@hrd-s.com", Name: "i-cube03" },
    { Email: "uw_denkidocs@hrd-s.com", Name: "uw_denkidocs" },
    { Email: "plan-application@hrd-s.com", Name: "plan-application" },
    { Email: "sujikai07@hrd-s.com", Name: "sujikai07" },
    { Email: "naisou@hrd-s.com", Name: "naisou" },
  ];
};

/**
 * * UPDATING AWS CORRECTION USER MASTERLIST
 * ! RUN ONCE THE FUNCTION TO AUTOMATICALLY UPDATE ALL USERS
 */

const UpdateMasterlist = () => {
  axios.get("http://hrdapps70:3001/api/employees").then(async (res) => {
    const record = [...res.data.records];
    let correctionUser = [];
    await axios
      .get(
        "https://yw0pyfesq3.execute-api.ap-southeast-1.amazonaws.com/prod/api/getCorrectionUser",
        {
          headers: {
            "x-api-key": "ZUe7gbLExb2OyqUHGdwXT9r8whee39ivabgYIXdJ",
          },
        }
      )
      .then((res) => (correctionUser = [...res.data]));

    const filterRecord = record
      .filter((el) =>
        correctionUser.some((check) => check.EmployeeCode == el.EmployeeCode)
      )
      .map((el) => {
        return {
          EmployeeCode: el.EmployeeCode,
          Department: el.DepartmentName,
          Section: el.SectionName || "-",
          Team: el.TeamName || "-",
          Position: el.DesignationName,
        };
      });

    for (let data of filterRecord)
      await axios
        .put(
          "https://yw0pyfesq3.execute-api.ap-southeast-1.amazonaws.com/prod/api/updateCorrectionUser",
          { data: data },
          {
            headers: {
              "x-api-key": "ZUe7gbLExb2OyqUHGdwXT9r8whee39ivabgYIXdJ",
            },
          }
        )
        .then(() => console.log({ Message: "Success" }))
        .catch(() => console.log("err"));
  });
};

export default {
  alert,
  inquiryType,
  auth,
  normalForCorrection,
  mobileForCorrection,
  EmployeePicture,
  additionalEmail,
  UpdateMasterlist,
};
