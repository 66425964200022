import Vue from "vue";
import Vuex from "vuex";
import VuexPersist  from "vuex-persistedstate";
// import replyTable from "../functions/httpRequest/ExternalConnection/ReplyTable";

const createPersistedState = new VuexPersist({
  key: "vuex",
  storage: localStorage,
  reducer: (state) => ({
    InquiryKind:state.InquiryKind,
    userinfo: state.userinfo,
    ReplyID:state.ReplyID,
    DraftID: state.DraftID,
    Organization:state.Organization,
    test: state.test,
  }),
});


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    InquiryKind:{},
    userinfo: {},
    ReplyID: "",
    DraftID: "",
    Organization:{},
    test: [],
    emailReply:{},
    emailID:"",
    
  },
  mutations: {
    LOG_OUT:(state, data)=>{
      // console.log(data)
      if(state.test.includes(data)) {
        let index = state.test.indexOf(data)
        
        state.test.splice(index,1)
      }
      // state.test = []
      // console.log(state.test)
    },
    GET_REPLY_ID:(state,data)=>{
      state.ReplyID=data
    },
    testArr: (state, data) => {
      // console.log(data)
      if(!state.test.includes(data)) state.test.push(data);
    },
    InquiryCode:(state, data) =>{
       state.inquirycode = data; 
    },
    ADD_USER_INFORMATION: (state, data) => {
      // console.log(data)
      state.userinfo = data;
    },
    ReplyID: (state, data) => {
      state.ReplyID = data;
    },
    Organization: (state, data) => {
      // console.log(data,"From Store")
      state.Organization = data;
    },
    DraftID: (state, data) => {
      state.DraftID = data;
    },
    CHANGE_EMAIL_REPLY:(state,payload)=>{
      state.emailReply=payload
      state.emailID=payload.id
    }
    // Check_LogIn:(state,data)=>{
    //   const socket = data
    //   if(state.userinfo.EmployeeCode){
    //     socket.emit("OnlineChecking", state.userinfo.EmployeeCode);
    //   }
    //   // console.log(state.userinfo.EmployeeCode)
    //   socket.on("OnlineChecking", (msg) => {
    //     state.count = msg
    //     console.log(msg)
    //   });
      
    // }

  },
  actions: {},
  modules: {},
  plugins: [createPersistedState],
});
