import { mapState, mapMutations } from "vuex";
import replyTable from "../functions/httpRequest/ExternalConnection/ReplyTable";

//****************************************//
// MUTATION FOR STORING USER INFORMATION  //
//****************************************//

const myPlugins = {
  install(Vue) {
    Vue.mixin({
      data: () => ({
        api: process.env.VUE_APP_URL,
        seenBy:null,
      }),
      computed: {
        ...mapState(["userinfo","Organization","emailReply","emailID"]),
      },
      methods: {
        ...mapMutations(["ADD_USER_INFORMATION", "ReplyID", "DraftID", "testArr","LOG_OUT","CHANGE_EMAIL_REPLY"]),
       async received_Reply(data){
          try {
            let res =await replyTable.GetReplybyIDconvo(data)
            this.CHANGE_EMAIL_REPLY(res.data[0])
            return {status:"OK",data:res.data[0]}
          } catch (error) {
            return {status:"ERROR",data:null}
          }
        }
      },
    });
  },
};

export default myPlugins;
