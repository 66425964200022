/* eslint-disable no-console */

import { register } from "register-service-worker";
import Swal from 'sweetalert2'

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
      // window.Notification.requestPermission();
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
      Swal.fire({
        icon: 'info',
        title: 'インフォメーション',
        html: `<h4>新コンテンツ</h4><br>
        <span>Cｔｒｌキー + F5キーを押してください。</span><br>
        <span>Thank you</span>`,
      })
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
