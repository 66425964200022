import axios from "axios";
import keys from "../../alert";
import store from "../../../store"

//*******************************//
//  POST REQUEST IN REPLY TABLE  //
//********************** ********//
const httpRequest = {
  Post(data) {
    data.system_from = "AWS"
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertReply`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //************************************//
  //  GET USER REQUEST IN REPLY TABLE   //
  //************************************//
  GetReply(id) {
    let country = ""
    if (!store.state.userinfo.accType) {
      country = "philippines"
    } else {
      country = "japan"
    }

    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getUserReply/${id}/${country}`, keys.auth.apiKey)
    );
  },

  GetReplybyIDconvo(id) {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getUserReplyConvo/${id}`, keys.auth.apiKey)
    );
  },

  //*******************************************//
  //   POST NEW REPLY REQUEST IN REPLY TABLE   //
  //*******************************************//
  NewReply(data) {
    data.system_from = "AWS"
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertNewReply`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  getAllInboxDepartment(data){
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/getToDeptConfirmation`,
        data,
        keys.auth.apiKey
      )
    );
  }
};

export default httpRequest;
