import Vue from "vue";
import App from "./App.vue";
import ScrollBar from "@morioh/v-smooth-scrollbar";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import myPlugins from "./plugins/MyMixins";
import axios from "axios";
import i18n from "./i18n";
require('./dialog_movable')
// import io from "socket.io-client";
import VueHtmlToPaper from "vue-html-to-paper";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueConfirmDialog from "vue-confirm-dialog";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import VueToastify from "vue-toastify";
// import io from 'socket.io-client';

// const socket = io("https://iconntest.hrd-s.com",{
//     transports: ["websocket"]
// });

// // Vue.prototype.$socket = socket;
// import VueSocketIO from 'vue-socket.io'

// Vue.use(new VueSocketIO({
//   debug: false, // Set to true for debugging purposes
//   connection: "http://localhost:3001",
//   options: {
//     transports: ["websocket"]
//   },
//   vuex: {
//     store,
//     actionPrefix: "SOCKET_",
//     mutationPrefix: "SOCKET_"
//   }
// }));




// Vue.prototype.$io =
//   process.env.NODE_ENV == "development"
//     ? io("https://iconntest.hrd-s.com")
//     : io("https://iconntest.hrd-s.com");

// Global register
// axios.defaults.withCredentials = true

// Print Option
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
  ],
};

// VUE TOAST
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
});
Vue.use(ScrollBar);
Vue.use(myPlugins);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueConfirmDialog);
Vue.use(VueToastify);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

// WEBSOCKET 10.169.140.114
// Vue.prototype.$io =
//   process.env.NODE_ENV == "development"
//     ? io("http://localhost:3001")
//     : io("http://localhost:3001");

//     // ? io("http://10.169.130.88:3001")
//     // : io("http://localhost:3001");


// Language Switcher / Prototype

let DefaultLanguage = "";

if (store.state.userinfo.Position) DefaultLanguage = "en";
else DefaultLanguage = "ja";

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (!language) language = DefaultLanguage;
  if (to.fullPath == "/login") language = "ja";
  i18n.locale = language;
  next();
});

// Global Variable 
//10.169.140.114
//10.169.142.83
Vue.prototype.$axios = axios;
Vue.prototype.$HRDEmployeeApi = "http://hrdapps70:3001/api/employees/";
Vue.prototype.$BaseUrl =
  process.env.NODE_ENV == "development"
    ? "http://10.169.142.104:3000/api"
    : process.env.VUE_APP_APIGATEWAY;

Vue.prototype.$BaseApiKey = {
  headers: { "x-api-key": process.env.VUE_APP_BASEAPIKEY },
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
